var pre = '/userCenter/';
export default {
  path: '/userCenter',
  title: '组织机构管理',
  header: 'userCenter',
  custom: 'ivu-icon ivu-icon-md-people',
  children: [{
    path: "".concat(pre, "organizational-manage"),
    title: '机构管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "organizational-manage/type-manage"),
      title: '类型管理'
    }, {
      path: "".concat(pre, "organizational-manage/configure"),
      title: '机构配置'
    }, {
      path: "".concat(pre, "organizational-manage/role-manage"),
      title: '角色&成员管理'
    }, {
      path: "".concat(pre, "organizational-manage/data-manage"),
      title: '数据权限'
    }]
  }, {
    path: "".concat(pre, "process-manage"),
    title: '流程管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "process-manage/process"),
      title: '流程管理'
    } // {
    //     path: `${pre}role-manage/user`,
    //     title: '用户管理'
    // },
    ]
  }, {
    path: "".concat(pre, "data-manage"),
    title: '数据管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "data-manage/article"),
      title: '文章数据'
    }, {
      path: "".concat(pre, "data-manage/material"),
      title: '素材数据'
    }, {
      path: "".concat(pre, "data-manage/overview"),
      title: '数据概览'
    }]
  }]
};