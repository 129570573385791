var pre = '/sys/';
export default {
  path: '/sys',
  title: '数据权限',
  header: 'sys',
  custom: 'ivu-icon ivu-icon-ios-analytics',
  children: [{
    path: "".concat(pre, "menu-manage"),
    title: '权限池',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "permission-pool/menu-manage"),
      title: '菜单操作权限'
    }, {
      path: "".concat(pre, "permission-pool/content-manage"),
      title: '内容权限'
    }]
  }, {
    path: "".concat(pre, "dict"),
    title: '数据字典',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "dict/dataDict"),
      title: '字典管理'
    }]
  }]
};