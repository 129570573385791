var pre = '/syntheticalCenter/';
export default {
  path: '/syntheticalCenter',
  title: '综合中心',
  header: 'syntheticalCenter',
  custom: 'ivu-icon ivu-icon-md-desktop',
  children: [{
    path: "".concat(pre, "systemManager"),
    title: '系统管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "systemManager/permission"),
      title: '权限分配'
    }, {
      path: "".concat(pre, "systemManager/sysSettings"),
      title: '系统配置'
    }]
  }, {
    path: "".concat(pre, "statistics"),
    title: '统计分析',
    // custom: 'i-icon-demo i-icon-demo-form',
    children: [{
      path: "".concat(pre, "statistics/calculation-rules"),
      title: '计算规则'
    }, {
      path: "".concat(pre, "statistics/view-app"),
      title: '数据分析'
    }]
  }]
};