function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'appCenter-';
export default {
  path: '/appCenter',
  name: 'appCenter',
  redirect: {
    name: "".concat(pre, "global/Basics")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'global/Basics',
    name: "".concat(pre, "Basics"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      // cache:true,
      title: '基础配置'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/Basics');
    }
  }, {
    path: 'global/Basics/edit',
    name: "".concat(pre, "BasicsEdit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增/编辑'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/Basics/modal');
    }
  }, {
    path: 'global/Basics/column',
    name: "".concat(pre, "Basicscolumn"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增/编辑'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/Basics/column');
    }
  }, {
    path: 'global/column',
    name: "".concat(pre, "column"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '栏目配置'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/column');
    }
  }, {
    path: 'global/column/edit',
    name: "".concat(pre, "column-edit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '栏目编辑'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/column/edit');
    }
  }, {
    path: 'global/templates/edit',
    name: "".concat(pre, "templates"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      // keepAlive: true,
      cache: true,
      title: '查看/配置模板'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/templates/edit');
    }
  }, {
    path: 'global/templates/addPlate',
    name: "addPlate",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      keepAlive: true,
      cache: true,
      title: '新增板块'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/templates/addPlate');
    }
  }, {
    path: 'global/templates',
    name: "".concat(pre, "templates"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '模板配置'
    }),
    component: function component() {
      return import('@/pages/appCenter/global/templates');
    }
  }, {
    path: 'app-components/compoenets',
    name: "".concat(pre, "compoenets"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '组件配置'
    }),
    component: function component() {
      return import('@/pages/appCenter/app-components/compoenets');
    }
  }, {
    path: 'pack/record',
    name: "".concat(pre, "record"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '打包记录'
    }),
    component: function component() {
      return import('@/pages/appCenter/pack/record');
    }
  }, {
    path: 'pack/material',
    name: "".concat(pre, "material"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '打包资料'
    }),
    component: function component() {
      return import('@/pages/appCenter/pack/material');
    }
  }, {
    path: 'view-app',
    name: "".concat(pre, "view-app"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '数据分析'
    }),
    component: function component() {
      return import('@/pages/appCenter/view-app');
    }
  }, {
    path: 'data-analysis',
    name: "".concat(pre, "data-analysis"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户-数据分析'
    }),
    component: function component() {
      return import('@/pages/appCenter/data-analysis');
    }
  }, {
    path: 'offline-news',
    name: "".concat(pre, "offline-news"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '离线新闻配置'
    }),
    component: function component() {
      return import('@/pages/appCenter/offline-news');
    }
  }]
};