var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    { staticClass: "i-layout" },
    [
      !_vm.isMobile && !_vm.hideSider && !_vm.isHome
        ? _c(
            "Sider",
            {
              staticClass: "i-layout-sider",
              class: _vm.siderClasses,
              attrs: { width: _vm.menuSideWidth }
            },
            [
              _c("i-menu-side", {
                attrs: {
                  "hide-logo":
                    _vm.isHeaderStick && _vm.headerFix && _vm.showHeader
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "Layout",
        { staticClass: "i-layout-inside", class: _vm.insideClasses },
        [
          _c(
            "transition",
            { attrs: { name: "fade-quick" } },
            [
              _c(
                "Header",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showHeader,
                      expression: "showHeader"
                    },
                    {
                      name: "resize",
                      rawName: "v-resize",
                      value: _vm.handleHeaderWidthChange,
                      expression: "handleHeaderWidthChange"
                    }
                  ],
                  staticClass: "i-layout-header",
                  class: _vm.headerClasses,
                  style: _vm.headerStyle
                },
                [
                  _vm.isMobile && _vm.showMobileLogo
                    ? _c("i-header-logo")
                    : _vm._e(),
                  !_vm.isMobile && _vm.isHeaderStick && _vm.headerFix
                    ? _c("i-header-logo")
                    : _vm._e(),
                  (_vm.isMobile || _vm.showSiderCollapse) && !_vm.hideSider
                    ? _c("i-header-collapse", {
                        on: { "on-toggle-drawer": _vm.handleToggleDrawer }
                      })
                    : _vm._e(),
                  _vm.headerMenu && !_vm.isMobile
                    ? _c("i-menu-head", { ref: "menuHead" })
                    : _vm._e(),
                  _vm.showBreadcrumb &&
                  !_vm.headerMenu &&
                  !_vm.isMobile &&
                  !_vm.isHome
                    ? _c("i-header-breadcrumb", { ref: "breadcrumb" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "i-layout-header-right" },
                    [
                      !_vm.isMobile && _vm.showReload
                        ? _c("i-header-reload", {
                            on: { "on-reload": _vm.handleReload }
                          })
                        : _vm._e(),
                      _vm.headerMenu && _vm.isMobile
                        ? _c("i-menu-head")
                        : _vm._e(),
                      _vm.isDesktop && _vm.showLog
                        ? _c("i-header-log")
                        : _vm._e(),
                      _vm.isDesktop && _vm.showFullscreen
                        ? _c("i-header-fullscreen")
                        : _vm._e(),
                      _c("i-header-user")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "Content",
            { staticClass: "i-layout-content", class: _vm.contentClasses },
            [
              _c(
                "transition",
                { attrs: { name: "fade-quick" } },
                [
                  _vm.tabs
                    ? _c("i-tabs", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHeader,
                            expression: "showHeader"
                          }
                        ],
                        on: { "on-reload": _vm.handleReload }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "i-layout-content-main" },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: _vm.keepAlive } },
                    [_vm.loadRouter ? _c("router-view") : _vm._e()],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("i-copyright")
        ],
        1
      ),
      _vm.isMobile && !_vm.hideSider
        ? _c(
            "div",
            [
              _c(
                "Drawer",
                {
                  attrs: {
                    placement: "left",
                    closable: false,
                    "class-name": _vm.drawerClasses
                  },
                  model: {
                    value: _vm.showDrawer,
                    callback: function($$v) {
                      _vm.showDrawer = $$v
                    },
                    expression: "showDrawer"
                  }
                },
                [_c("i-menu-side")],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }