import request from '@/plugins/request';
import qs from 'qs';
var appMark = 'zhongou';
localStorage.setItem('appmark', appMark);
console.log('--------------入口appMark:' + appMark + '------------------');
export function AccountLogin(params) {
  return request({
    url: '/omnimedia-web-usercenter/user/login',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    } // 添加请求头**

  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data: data
  });
} //获取图形验证码

export function GetVerifyCode(params) {
  return request({
    url: '/omnimedia-web-usercenter/user/verifyCode',
    method: 'get',
    params: params,
    // responseType:'blob',
    headers: {
      'appMark': appMark + '.app'
    } // 添加请求头**

  });
} //用户信息列表

export function GetUserList(params) {
  return request({
    url: '/omnimedia-web-usercenter/user/queryUserList',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //新增/编辑用户

export function AddUser(data) {
  return request({
    url: '/omnimedia-web-usercenter/user/addUser',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}