function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'form-';
export default {
  path: '/form',
  name: 'form',
  redirect: {
    name: "".concat(pre, "basic-form")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'basic-form',
    name: "".concat(pre, "basic-form"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '基础表单'
    }),
    component: function component() {
      return import('@/pages/form/basic-form');
    }
  }, {
    path: 'advanced-form',
    name: "".concat(pre, "advanced-form"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '高级表单'
    }),
    component: function component() {
      return import('@/pages/form/advanced-form');
    }
  }, {
    path: 'step-form',
    name: "".concat(pre, "step-form"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '分步表单'
    }),
    component: function component() {
      return import('@/pages/form/step-form');
    }
  }, {
    path: 'detail',
    name: "".concat(pre, "detail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '表单详情'
    }),
    component: function component() {
      return import('@/pages/form/detail');
    }
  }]
};