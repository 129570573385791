// 菜单，侧边栏
// import dashboard from './modules/dashboard';
// import form from './modules/form';
// import list from './modules/list';
// import search from './modules/search';
// import profile from './modules/profile';
// import result from './modules/result';
// import exception from './modules/exception';
// import setting from './modules/setting';
// import editor from './modules/editor';
// import tool from './modules/tool';
import adCenter from './modules/adCenter';
import appCenter from './modules/appCenter';
import contentCenter from './modules/contentCenter';
import userManage from './modules/userManage';
import syntheticalCenter from './modules/syntheticalCenter';
import menuManage from './modules/menuManage'; // 系统
// import log from './modules/log';
//首页

import Home from './modules/Home'; //app编辑（模板配置）

import appEdit from './modules/appEdit';
import editArticle from './modules/editArticle';
import editInstitutionalArticles from './modules/editInstitutionalArticles';
import editIconComponents from './modules/editIconComponents';
import data_analysis from './modules/data_analysis';
import data_analysis_user from './modules/data_analysis_user';
export default [// dashboard,
adCenter, appCenter, contentCenter, userManage, syntheticalCenter, menuManage, // form,
// list,
// search,
// profile,
// result,
// exception,
// setting,
// editor,
// tool,
// {
//     path: '/i18n',
//     title: '$t:menu.i18n',
//     header: 'home',
//     custom: 'i-icon-demo i-icon-demo-i18n'
// },
// log,
appEdit, editArticle, editInstitutionalArticles, data_analysis, data_analysis_user, editIconComponents, Home];