export default {
  locale: 'fr-FR',
  language: 'Français',
  menu: {
    i18n: 'Multilingue'
  },
  page: {
    login: {
      title: 'Connexion',
      remember: 'Connexion automatique',
      forgot: 'Mot de passe oublié',
      submit: 'Connexion',
      other: 'Autres méthodes de connexion',
      signup: 'Créer un compte'
    },
    register: {
      title: 'Inscription',
      submit: 'Inscription',
      other: 'Se connecter avec un compte existant'
    },
    exception: {
      e403: 'Désolé, vous n\'avez pas l\'autorisation d\'accéder à cette page',
      e404: 'Désolé, la page que vous recherchez n\'existe pas',
      e500: 'Désolé, une erreur s\'est produite sur le serveur',
      btn: 'Retour à la page d\'accueil'
    },
    i18n: {
      content: 'Bonjour, ravi de faire votre connaissance !'
    }
  }
};