var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "i-layout-header-trigger i-layout-header-trigger-min" },
    [
      _c(
        "Dropdown",
        {
          staticClass: "i-layout-header-user",
          class: { "i-layout-header-user-mobile": _vm.isMobile },
          attrs: { trigger: _vm.isMobile ? "click" : "hover" },
          on: { "on-click": _vm.handleClick }
        },
        [
          _vm.info.avatar
            ? _c("Avatar", { attrs: { size: "small", src: _vm.info.avatar } })
            : _vm._e(),
          !_vm.isMobile
            ? _c("span", { staticClass: "i-layout-header-user-name" }, [
                _vm._v(_vm._s(_vm.info.name))
              ])
            : _vm._e(),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c(
                "i-link",
                { attrs: { to: "/setting/user" } },
                [
                  _c(
                    "DropdownItem",
                    [
                      _c("Icon", { attrs: { type: "ios-contact-outline" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicLayout.user.center")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "DropdownItem",
                { attrs: { divided: "", name: "resetPsw" } },
                [
                  _c("Icon", { attrs: { type: "ios-settings" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicLayout.user.resetPsw")))
                  ])
                ],
                1
              ),
              _c(
                "DropdownItem",
                { attrs: { divided: "", name: "logout" } },
                [
                  _c("Icon", { attrs: { type: "ios-log-out" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicLayout.user.logOut")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "重置密码",
            "footer-hide": "",
            width: "500",
            "label-position": "top"
          },
          model: {
            value: _vm.resetModal,
            callback: function($$v) {
              _vm.resetModal = $$v
            },
            expression: "resetModal"
          }
        },
        [
          _c("Alert", { attrs: { type: "warning" } }, [
            _vm._v(_vm._s(_vm.$t("basicLayout.login.tips")))
          ]),
          _c(
            "Form",
            {
              ref: "formCustom",
              attrs: { model: _vm.formCustom, rules: _vm.ruleCustom }
            },
            [
              _c(
                "FormItem",
                {
                  attrs: {
                    label: _vm.$t("basicLayout.login.oldPassword"),
                    prop: "oldPassword"
                  }
                },
                [
                  _c("Input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formCustom.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.formCustom, "oldPassword", $$v)
                      },
                      expression: "formCustom.oldPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  attrs: {
                    label: _vm.$t("basicLayout.login.newPsw"),
                    prop: "passwd"
                  }
                },
                [
                  _c("Input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formCustom.passwd,
                      callback: function($$v) {
                        _vm.$set(_vm.formCustom, "passwd", $$v)
                      },
                      expression: "formCustom.passwd"
                    }
                  })
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  attrs: {
                    label: _vm.$t("basicLayout.login.passwdCheck"),
                    prop: "passwdCheck"
                  }
                },
                [
                  _c("Input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formCustom.passwdCheck,
                      callback: function($$v) {
                        _vm.$set(_vm.formCustom, "passwdCheck", $$v)
                      },
                      expression: "formCustom.passwdCheck"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "submitbox" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit("formCustom")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("basicLayout.login.submit")))]
              ),
              _c(
                "Button",
                {
                  staticStyle: { "margin-left": "8px" },
                  on: {
                    click: function($event) {
                      return _vm.handleReset("formCustom")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("basicLayout.login.reset")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }