function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import dayjs from 'dayjs';
import { get } from 'lodash';
import util from '@/libs/util.js';
export default {
  namespaced: true,
  state: {
    /**
     * @description 错误日志，单条属性：
     * message: 必填，日志信息
     * type: 非必填，类型，可选值为 info（默认值）| success | warning | error，其中 error 会以具体数目强调显示，其它以点轻量显示
     * time: 必填，日志记录时间
     * meta: 非必填，其它携带信息
     * */
    log: []
  },
  getters: {
    /**
     * @description 返回现存 log (all) 的条数
     * @param {*} state vuex state
     */
    length: function length(state) {
      return state.log.length;
    },

    /**
     * @description 返回现存 log (error) 的条数
     * @param {*} state vuex state
     */
    lengthError: function lengthError(state) {
      return state.log.filter(function (log) {
        return log.type === 'error';
      }).length;
    }
  },
  actions: {
    /**
     * @description 添加一个日志
     * @param {String} param message {String} 信息
     * @param {String} param type {String} 类型
     * @param {Object} param meta {Object} 附带的信息
     */
    push: function push(_ref, _ref2) {
      var rootState = _ref.rootState,
          commit = _ref.commit;
      var message = _ref2.message,
          _ref2$type = _ref2.type,
          type = _ref2$type === void 0 ? 'info' : _ref2$type,
          meta = _ref2.meta;
      commit('push', {
        message: message,
        type: type,
        time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        meta: _objectSpread({
          // 当前用户信息
          user: rootState.admin.user.info,
          // 当前用户的 uuid
          uuid: util.cookies.get('uuid'),
          // 当前的 token
          token: util.cookies.get('token'),
          // 当前地址
          url: get(window, 'location.href', '')
        }, meta)
      });
    }
  },
  mutations: {
    /**
     * @description 添加日志
     * @param {Object} state vuex state
     * @param {Object} log data
     */
    push: function push(state, log) {
      state.log.push(log);
    },

    /**
     * @description 清空日志
     * @param {Object} state vuex state
     */
    clean: function clean(state) {
      state.log = [];
    }
  }
};