function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'syntheticalCenter-';
export default {
  path: '/syntheticalCenter',
  name: 'syntheticalCenter',
  redirect: {
    name: "".concat(pre, "systemManager/permission")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'systemManager/permission',
    name: "".concat(pre, "permission"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '权限分配'
    }),
    component: function component() {
      return import('@/pages/syntheticalCenter/systemManager/permission');
    }
  }, {
    path: 'systemManager/sysSettings',
    name: "".concat(pre, "sysSettings"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '系统配置'
    }),
    component: function component() {
      return import('@/pages/syntheticalCenter/systemManager/sysSettings');
    }
  }, {
    path: 'statistics/calculation-rules',
    name: "".concat(pre, "calculation-rules"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '计算规则'
    }),
    component: function component() {
      return import('@/pages/syntheticalCenter/statistics/calculation-rules');
    }
  }, {
    path: 'statistics/view-app',
    name: "".concat(pre, "view-app"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '数据分析'
    }),
    component: function component() {
      return import('@/pages/syntheticalCenter/statistics/view-app');
    }
  }, {
    path: 'statistics/data-analysis',
    name: "data-analysis",
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '内容分析'
    }),
    component: function component() {
      return import('@/pages/syntheticalCenter/statistics/data-analysis');
    }
  }]
};