function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'tool-';
export default {
  path: '/tool',
  name: 'tool',
  redirect: {
    name: "".concat(pre, "page-cache-on")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'frame/iview',
    name: "".concat(pre, "frame-iview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'View UI 官网'
    }),
    component: function component() {
      return import('@/pages/tool/frame/iview');
    }
  }, {
    path: 'frame/iqiyi',
    name: "".concat(pre, "frame-iqiyi"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '爱奇艺'
    }),
    component: function component() {
      return import('@/pages/tool/frame/iqiyi');
    }
  }, {
    path: 'page-cache/on',
    name: "".concat(pre, "page-cache-on"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '开启缓存'
    }),
    component: function component() {
      return import('@/pages/tool/page-cache/on');
    }
  }, {
    path: 'page-cache/off',
    name: "".concat(pre, "page-cache-off"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: false,
      title: '关闭缓存'
    }),
    component: function component() {
      return import('@/pages/tool/page-cache/off');
    }
  }, {
    path: 'page-cache/params/:id',
    name: "".concat(pre, "page-cache-params"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '带参路由缓存'
    }),
    props: true,
    component: function component() {
      return import('@/pages/tool/page-cache/params');
    }
  }, {
    path: 'log/record',
    name: "".concat(pre, "record"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '日志记录'
    }),
    component: function component() {
      return import('@/pages/tool/log/record');
    }
  }, {
    path: 'log/error',
    name: "".concat(pre, "error"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '错误捕捉'
    }),
    component: function component() {
      return import('@/pages/tool/log/error');
    }
  }, {
    path: 'log/ajax',
    name: "".concat(pre, "ajax"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'Ajax 错误'
    }),
    component: function component() {
      return import('@/pages/tool/log/ajax');
    }
  }, {
    path: 'log/console',
    name: "".concat(pre, "console"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '控制台日志'
    }),
    component: function component() {
      return import('@/pages/tool/log/console');
    }
  }]
};