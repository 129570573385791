//
//
//
//
//
//
//
//
//
//
import tTitle from '../mixins/translate-title';
export default {
  name: 'iMenuSideTitle',
  mixins: [tTitle],
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    // 用于侧边栏收起 Dropdown 当前高亮
    selected: {
      type: Boolean,
      default: false
    }
  }
};