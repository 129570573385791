var pre = '/appCenter/';
export default {
  path: '/appCenter',
  title: '应用中心',
  header: 'appCenter',
  custom: 'i-icon-demo i-icon-demo-form',
  children: [{
    path: "".concat(pre, "global"),
    title: 'APP配置管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    auth: ['admin'],
    children: [{
      path: "".concat(pre, "global/Basics"),
      title: '基础配置' // children: [
      //     {
      //         path: `${pre}global/Basics/edit`,
      //         title: '新增/编辑',
      //     }
      // ]

    }, // {
    //     path: `${pre}global/column`,
    //     title: '栏目配置'
    // },
    {
      path: "".concat(pre, "global/templates"),
      title: '模板配置'
    }]
  }, {
    path: "".concat(pre, "app-components"),
    title: '组件管理',
    // custom: 'i-icon-demo i-icon-demo-form',
    auth: ['admin'],
    children: [{
      path: "".concat(pre, "app-components/compoenets"),
      title: '组件配置'
    }]
  }, {
    path: "".concat(pre, "pack"),
    title: '打包上架',
    // custom: 'i-icon-demo i-icon-demo-form',
    auth: ['admin'],
    children: [{
      path: "".concat(pre, "pack/record"),
      title: '打包记录'
    }, {
      path: "".concat(pre, "pack/material"),
      title: '打包资料'
    }]
  }, {
    path: "".concat(pre, "offline-news"),
    title: '离线新闻配置',
    children: [{
      path: "".concat(pre, "offline-news"),
      title: '离线新闻配置'
    }]
  }, {
    path: "".concat(pre, "view-app"),
    title: '数据分析',
    auth: ['zhubian', 'admin']
  }]
};