import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0abd3a1e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\work\\work-2021\\gghl\\European-new-media\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0abd3a1e')) {
      api.createRecord('0abd3a1e', component.options)
    } else {
      api.reload('0abd3a1e', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=0abd3a1e&", function () {
      api.rerender('0abd3a1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/basic-layout/header-log/index.vue"
export default component.exports