//
//
//
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [// {
        //     title: '官网',
        //     key: '官网',
        //     href: 'https://iview.design',
        //     blankTarget: true
        // },
        // {
        //     title: '社区',
        //     key: '社区',
        //     href: 'https://dev.iviewui.com',
        //     blankTarget: true
        // },
        // {
        //     title: '专业版',
        //     key: '专业版',
        //     href: 'https://pro.iviewui.com',
        //     blankTarget: true
        // }
      ],
      copyright: 'Copyright © 2022 国广互联科技(北京)有限公司'
    };
  }
};