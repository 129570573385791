/**
 * 工作流
 * */
export default {
  namespaced: true,
  //开启namespace:true，该模块就成为命名空间模块
  state: {
    // 组件信息
    info: {}
  },
  mutations: {
    SETWOKRFLOWDATA: function SETWOKRFLOWDATA(state, info) {
      state.info = info;
    }
  },
  actions: {
    setWorkflow: function setWorkflow(obj) {
      obj.commit('SETCOMPONENTS');
    }
  }
};