var hasPermission = {
  install: function install(Vue, options) {
    Vue.directive('has', {
      inserted: function inserted(el, binding, vnode) {
        // console.log('------------perms')
        var permTypes = vnode.context.$route.meta.perms;

        if (permTypes && !permTypes.includes(binding.value)) {
          el.parentNode.removeChild(el);
        }
      }
    });
  }
};
export default hasPermission;