import Vue from 'vue'; // 在vue上挂载一个指量 preventReClick

export default {
  install: function install(Vue) {
    // 防重复点击(指令实现)
    Vue.directive('preventReClick', {
      inserted: function inserted(el, binding) {
        el.addEventListener('click', function () {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(function () {
              el.disabled = false;
            }, binding.value || 3000);
          }
        });
      }
    });
  }
};