var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i-link",
    {
      staticClass: "i-layout-header-logo",
      class: { "i-layout-header-logo-stick": !_vm.isMobile },
      attrs: { to: "/" }
    },
    [
      _c("img", { attrs: { src: require("@/assets/images/logo_new.png") } }),
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("basicLayout.home.logoText")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }