function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: ['admin']
};
var pre = 'dashboard-';
export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: {
    name: "".concat(pre, "console")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'console',
    name: "".concat(pre, "console"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '主控台' // closable: false

    }),
    component: function component() {
      return import('@/pages/dashboard/console');
    }
  }, {
    path: 'monitor',
    name: "".concat(pre, "monitor"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '监控页'
    }),
    component: function component() {
      return import('@/pages/dashboard/monitor');
    }
  }, {
    path: 'workplace',
    name: "".concat(pre, "workplace"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '工作台'
    }),
    component: function component() {
      return import('@/pages/dashboard/workplace');
    }
  }]
};