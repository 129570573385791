import "core-js/modules/es6.array.copy-within";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.array.of";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.species";
import "core-js/modules/es6.date.to-json";
import "core-js/modules/es6.date.to-primitive";
import "core-js/modules/es6.function.has-instance";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.map";
import "core-js/modules/es6.math.acosh";
import "core-js/modules/es6.math.asinh";
import "core-js/modules/es6.math.atanh";
import "core-js/modules/es6.math.cbrt";
import "core-js/modules/es6.math.clz32";
import "core-js/modules/es6.math.cosh";
import "core-js/modules/es6.math.expm1";
import "core-js/modules/es6.math.fround";
import "core-js/modules/es6.math.hypot";
import "core-js/modules/es6.math.imul";
import "core-js/modules/es6.math.log1p";
import "core-js/modules/es6.math.log10";
import "core-js/modules/es6.math.log2";
import "core-js/modules/es6.math.sign";
import "core-js/modules/es6.math.sinh";
import "core-js/modules/es6.math.tanh";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.epsilon";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.is-safe-integer";
import "core-js/modules/es6.number.max-safe-integer";
import "core-js/modules/es6.number.min-safe-integer";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es7.object.define-getter";
import "core-js/modules/es7.object.define-setter";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.object.freeze";
import "core-js/modules/es6.object.get-own-property-descriptor";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.get-own-property-names";
import "core-js/modules/es6.object.get-prototype-of";
import "core-js/modules/es7.object.lookup-getter";
import "core-js/modules/es7.object.lookup-setter";
import "core-js/modules/es6.object.prevent-extensions";
import "core-js/modules/es6.object.is";
import "core-js/modules/es6.object.is-frozen";
import "core-js/modules/es6.object.is-sealed";
import "core-js/modules/es6.object.is-extensible";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.seal";
import "core-js/modules/es6.object.set-prototype-of";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.reflect.apply";
import "core-js/modules/es6.reflect.construct";
import "core-js/modules/es6.reflect.define-property";
import "core-js/modules/es6.reflect.delete-property";
import "core-js/modules/es6.reflect.get";
import "core-js/modules/es6.reflect.get-own-property-descriptor";
import "core-js/modules/es6.reflect.get-prototype-of";
import "core-js/modules/es6.reflect.has";
import "core-js/modules/es6.reflect.is-extensible";
import "core-js/modules/es6.reflect.own-keys";
import "core-js/modules/es6.reflect.prevent-extensions";
import "core-js/modules/es6.reflect.set";
import "core-js/modules/es6.reflect.set-prototype-of";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.set";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.string.anchor";
import "core-js/modules/es6.string.big";
import "core-js/modules/es6.string.blink";
import "core-js/modules/es6.string.bold";
import "core-js/modules/es6.string.code-point-at";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.string.fontcolor";
import "core-js/modules/es6.string.fontsize";
import "core-js/modules/es6.string.from-code-point";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.string.italics";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.string.pad-end";
import "core-js/modules/es6.string.raw";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.string.small";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.strike";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.string.sup";
import "core-js/modules/es6.typed.array-buffer";
import "core-js/modules/es6.typed.int8-array";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.typed.uint8-clamped-array";
import "core-js/modules/es6.typed.int16-array";
import "core-js/modules/es6.typed.uint16-array";
import "core-js/modules/es6.typed.int32-array";
import "core-js/modules/es6.typed.uint32-array";
import "core-js/modules/es6.typed.float32-array";
import "core-js/modules/es6.typed.float64-array";
import "core-js/modules/es6.weak-map";
import "core-js/modules/es6.weak-set";
import "core-js/modules/web.timers";
import "core-js/modules/web.immediate";
import "core-js/modules/web.dom.iterable";
import "regenerator-runtime/runtime";
// Vue
import Vue from 'vue';
import App from './App'; // 配置

import Setting from './setting'; // 混合

import mixinApp from '@/mixins/app'; // 插件

import plugins from '@/plugins'; //滑动导航插件

import LyTab from 'ly-tab'; // store

import store from '@/store/index'; // iView 和 iView Pro

import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js'; // 菜单和路由

import router from './router';
import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes'; // 多语言

import _i18n from '@/i18n'; // 方法

import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system'; //RAS加密工具

import JsEncrypt from 'jsencrypt'; // 内置组件

import iLink from '@/components/link';
import iFrame from '@/components/frame'; //粒子效果

import VueParticles from 'vue-particles'; //鉴权

import hasPermission from '@/libs/hasPermission'; //懒加载

import imgLazy from '@/libs/imgLazy.js';
Vue.directive('imgLazy', imgLazy);
import VeLine from 'v-charts/lib/line.common';
import VePie from 'v-charts/lib/pie.common';
import VeRing from 'v-charts/lib/ring.common'; // 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme

import './styles/index.less';
import './libs/iview-pro/iview-pro.css'; //import { $t } from './layouts/basic-layout/il';
//   Vue.use({
//     install(Vue) {
//       Vue.prototype.$t = $t;
//     }
//   });
//Vue.use($t)

if (window) window.$t = function (key, value) {
  return _i18n.t(key, value);
};
Vue.use(_i18n);
Vue.use(plugins);
Vue.use(VueParticles);
Vue.use(hasPermission);
Vue.use(ViewUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
/**
 * 配置全局的加密方法
 * @param obj 需要加密的字符串
 */

Vue.prototype.$encruption = function (obj) {
  var encrypt = new JsEncrypt(); // console.log(encrypt+'公钥方法')
  // 公钥

  encrypt.setPublicKey(Setting.setPublicKey);
  return encrypt.encrypt(obj);
};

Vue.use(iViewPro);
Vue.use(LyTab);
Vue.component('i-link', iLink);
Vue.component('i-frame', iFrame);
Vue.component(VeLine.name, VeLine);
Vue.component(VePie.name, VePie);
Vue.component(VeRing.name, VeRing);
import AES from "@/libs/aes.js";
new Vue({
  mixins: [mixinApp],
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('admin/menu/setHeader', menuHeader); // 加载用户登录的数据

    this.$store.dispatch('admin/account/load'); // 初始化全屏监听

    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(to, from) {
      var path = to.matched[to.matched.length - 1].path;
      var menuSider = JSON.parse(localStorage.getItem('setSider')) || []; //文章发布和机构文章发布特殊路径 需要去除掉，不显示在左侧的sider menu上

      removeTreeListItem(menuSider, '/editArticle');
      removeTreeListItem(menuSider, '/edit-institutionalArticles'); // console.log(Setting.dynamicSiderMenu)

      if (Setting.dynamicSiderMenu) {
        // 是否使用动态侧边菜单
        var headerName = getHeaderName(path, menuSider);

        if (headerName === null) {
          path = to.path;
          headerName = getHeaderName(path, menuSider);
        } // 在 404 时，是没有 headerName 的


        if (headerName !== null) {
          this.$store.commit('admin/menu/setHeaderName', headerName);
          this.$store.commit('admin/menu/setMenuSider', menuSider);
          var filterMenuSider = getMenuSider(menuSider, headerName);
          this.$store.commit('admin/menu/setSider', filterMenuSider);
          this.$store.commit('admin/menu/setActivePath', to.path);
          var openNames = getSiderSubmenu(path, menuSider);
          this.$store.commit('admin/menu/setOpenNames', openNames);
        }
      }

      this.appRouteChange(to, from);
    }
  }
}).$mount('#app'); // 根据path属性从数组（树结构）中移除元素

function removeTreeListItem(treeList, path) {
  if (!treeList || !treeList.length) {
    return;
  }

  for (var i = 0; i < treeList.length; i++) {
    if (treeList[i].path === path) {
      treeList.splice(i, 1);
      break;
    }

    removeTreeListItem(treeList[i].children, path);
  }
}