function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from "vuex";
import { changePasswords } from "@api/queryList";
export default {
  name: "iHeaderUser",
  data: function data() {
    var _this = this;

    var validatePass = function validatePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (_this.formCustom.passwdCheck !== "") {
          // 对第二个密码框单独验证
          _this.$refs.formCustom.validateField("passwdCheck");
        }

        callback();
      }
    };

    var validatePassCheck = function validatePassCheck(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次确认新密码"));
      } else if (value !== _this.formCustom.passwd) {
        callback(new Error("两次密码不一致！"));
      } else {
        callback();
      }
    };

    return {
      formCustom: {
        passwd: "",
        passwdCheck: "",
        oldPassword: ""
      },
      ruleCustom: {
        passwd: [{
          validator: validatePass,
          trigger: "blur"
        }, {
          type: "string",
          pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,18}$/,
          // 正则表达式验证
          message: " 必须输入大于8位数，由大小写字母、数字、特殊字符组成！",
          trigger: "blur"
        }],
        passwdCheck: [{
          validator: validatePassCheck,
          trigger: "blur"
        }]
      },
      resetModal: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState("admin/user", ["info"])), mapState("admin/layout", ["isMobile", "logoutConfirm"])),
  methods: _objectSpread(_objectSpread({}, mapActions("admin/account", ["logout"])), {}, {
    handleClick: function handleClick(name) {
      if (name === "logout") {
        this.logout({
          confirm: this.logoutConfirm,
          vm: this
        });
      }

      if (name === "resetPsw") {
        this.resetModal = true;
      }
    },
    handleSubmit: function handleSubmit(name) {
      var _this2 = this;

      this.$refs[name].validate(function (valid) {
        if (valid) {
          var data = {
            id: _this2.$store.state.admin.user.info.uid,
            oldPassword: _this2.$encruption(_this2.formCustom.oldPassword),
            newPassword: _this2.$encruption(_this2.formCustom.passwd)
          };
          changePasswords(data).then(function (res) {
            _this2.$Message.success("密码重置成功");

            _this2.resetModal = false;

            _this2.logout({
              vm: _this2
            });
          }).catch(function (err) {// this.$Message.error("修改失败");
          });
        } else {
          _this2.$Message.error("Fail!");
        }
      });
    },
    handleReset: function handleReset(name) {
      this.$refs[name].resetFields();
    }
  })
};