function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'iHeaderSetting',
  data: function data() {
    return {
      visible: false
    };
  },
  computed: _objectSpread({}, mapState('admin/layout', ['siderTheme', 'headerTheme', 'headerStick', 'siderFix', 'headerFix', 'headerHide', 'menuAccordion', 'showSiderCollapse', 'tabs', 'showTabsIcon', 'tabsFix', 'showBreadcrumb', 'showBreadcrumbIcon', 'showReload', 'showI18n', 'showCollapseMenuTitle'])),
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['updateLayoutSetting'])), {}, {
    showSetting: function showSetting() {
      this.visible = true;
    },
    handleChangeSetting: function handleChangeSetting(key, value) {
      console.log(key);
      this.updateLayoutSetting({
        key: key,
        value: value
      });
    }
  })
};