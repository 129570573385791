import cookies from './util.cookies';
import log from './util.log';
import db from './util.db';
import Setting from '@/setting';
import { Modal } from 'view-design';
var util = {
  cookies: cookies,
  log: log,
  db: db
}; //判断对象是否为空

util.objectValueAllEmpty = function (object) {
  var isEmpty = true;
  Object.keys(object).forEach(function (x) {
    if (object[x] != null && object[x] != "") {
      isEmpty = false;
    }
  });

  if (isEmpty) {
    //值全为空
    return true;
  }

  return false;
}; //获取图片数量


util.getimgnum = function (strHtml) {
  // console.log("strHtml:" + strHtml);
  if (strHtml === undefined || strHtml === null) {
    return 0;
  }

  var imgReg = /<img.*?(?:>|\/>)/gi;
  var arr = strHtml.match(imgReg);

  if (arr && arr.length > 0) {
    return arr.length;
  }

  return 0;
}; //获取视频数量


util.getvideonum = function (strHtml) {
  if (strHtml === undefined || strHtml === null) {
    return 0;
  }

  var videoReg = /<video.*?(?:>|\/>)/gi;
  var arr = strHtml.match(videoReg);

  if (arr && arr.length > 0) {
    return arr.length;
  }

  return 0;
}; //获取音频数量


util.getaudionum = function (strHtml) {
  if (strHtml === undefined || strHtml === null) {
    return 0;
  }

  var imgReg = /<audio.*?(?:>|\/>)/gi;
  var arr = strHtml.match(imgReg);

  if (arr && arr.length > 0) {
    return arr.length;
  }

  return 0;
}; //获取url参数


util.getUrlVars = function () {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}; //特殊字符验证


util.validateKeyword = function (rule, value, callback) {
  var reg = /[`~!@#$%^&*_\-=<>?:"{}|.\/;'\\[\]·~！@#￥%……&——\-={}|《》？：“”【】、；‘’。、]/im; // 检验规则为正则，可自行百度。

  if (reg.test(value)) {
    return callback(new Error("不能有特殊字符"));
  } else {
    callback();
  }
}; //压缩图片


util.photoCompress = function (file, w, objDiv) {
  var ready = new FileReader();
  /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/

  ready.readAsDataURL(file);

  ready.onload = function () {
    var re = this.result;
    util.canvasDataURL(re, w, objDiv);
  };
}, //随机字符串
util.randomString = function (len) {
  len = len || 32;
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxPos = $chars.length;
  var pwd = "";

  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return pwd;
}; //canvasDataURL

util.canvasDataURL = function (path, obj, callback) {
  var img = new Image();
  img.src = path;
  var str = util.randomString(6);

  img.onload = function () {
    var that = this; // 默认按比例压缩

    var w = that.width,
        h = that.height,
        scale = w / h;
    w = obj.width || w;
    h = obj.height || w / scale;
    var quality = 0.3; // 默认图片质量为0.7
    //生成canvas

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d"); // 创建属性节点

    var anw = document.createAttribute("width");
    anw.nodeValue = w;
    var anh = document.createAttribute("height");
    anh.nodeValue = h;
    canvas.setAttributeNode(anw);
    canvas.setAttributeNode(anh);
    ctx.drawImage(that, 0, 0, w, h); // 图像质量

    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      quality = obj.quality;
    } // quality值越小，所绘制出的图像越模糊


    var base64 = canvas.toDataURL("image/jpeg", quality); // base64再转file文件

    var arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        // 转成blob
    bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var files = new window.File([new Blob([u8arr], {
      type: mime
    })], str + ".jpeg", {
      type: "image/jpeg"
    }); // 转成file

    callback(files); // 回调
  };
}; //地域限制处理方法


util.articleExistRegion = function (res) {
  if (res === 'articleReleased' || res === null) {
    Modal.success({
      title: '提示',
      content: '操作成功'
    });
  } else if (res === 'articleNotExist') {
    Modal.error({
      title: '提示',
      content: '文章Id不存在！'
    });
  } else if (res === 'articleExistRegion') {
    Modal.warning({
      title: '提示',
      content: '该文章所属机构有地域限制，不支持配置。'
    });
  } else if (res === 'articleNotRelease') {
    Modal.warning({
      title: '提示',
      content: '抱歉，banner中有已被撤销审核或删除的文章，请再次核对'
    });
  }
}; //栏目树处理


util.mapTree = function (data) {
  data.forEach(function (item) {
    //遍历树 处理对应的title
    if (item.children == null || item.children == undefined) {
      delete item.children;
    }

    if (item.children) {
      util.mapTree(item.children);
    }
  }); // console.log(data)
}; //递归遍历树


util.treeIterator = function (tree, func) {
  tree.forEach(function (node) {
    func(node);
    node.children && util.treeIterator(node.children, func);
  });
}, //转换时间格式
util.renderTime = function (date) {
  var dates = new Date(date).toJSON();
  return new Date(+new Date(dates) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
};

util.getQueryString = function () {
  var url = location.search; //获取url中"?"符后的字串

  var theRequest = new Object();

  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    strs = str.split("&");

    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }

  return theRequest;
};

function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}
/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */


util.title = function (_ref) {
  var title = _ref.title,
      count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
}; //获取随机数


util.createNum = function (length) {
  var data = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  var nums = "";

  for (var i = 0; i < length; i++) {
    var r = parseInt(Math.random() * 61, 10);
    nums += data[r];
  }

  return nums;
}; // 防抖:多次触发事件后，事件处理函数只执行一次，并且是在触发操作结束时执行。


util.debounce = function (fn, delay) {
  var delay = delay || 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}; // 节流:触发函数事件后，短时间间隔内无法连续调用，只有上一次函数执行后，过了规定的时间间隔，才能进行下一次的函数调用。


util.throttle = function (fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
    var th = this;
    var args = arguments;
    var now = +new Date();

    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, interval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  };
}; //标准时间格式转换


util.changeTime = function (date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
};

util.fillDate = function () {
  //日期 
  var now = new Date(); //获取系统日期，即Sat Jul 29 08:24:48 UTC+0800 2006 

  var yy = now.getFullYear();
  ; //截取年，即2006 

  var mm = now.getMonth() + 1; //截取月，即07 

  var dd = now.getDate(); //截取日，即29 

  var cal = yy + "." + mm + "." + dd; //取时间 

  var hh = now.getHours(); //截取小时，即8 

  var mm = now.getMinutes(); //截取分钟，即34 

  var ss = now.getTime() % 60000; //获取时间，因为系统中时间是以毫秒计算的， 所以秒要通过余60000得到。 

  ss = (ss - ss % 1000) / 1000; //然后，将得到的毫秒数再处理成秒 

  var clock = hh + ':'; //将得到的各个部分连接成一个日期时间 

  if (mm < 10) clock += '0'; //字符串 

  clock += mm + ':';
  if (ss < 10) clock += '0';
  clock += ss; //向页面的相应位置进行赋值

  $(".date").html(cal + " " + clock);
  var timeID = setTimeout(fillDate, 1000);
}; //删除url指定参数


util.DelQueryString = function (url, paramKey) {
  if (!url) return "";
  var beforeUrl = url.substr(0, url.indexOf("?")); //?之前主地址

  var afterUrl = url.substr(url.indexOf("?") + 1); //？之后参数路径

  var nextUrl = "";
  var arr = new Array();

  if (afterUrl != "") {
    var urlParamArr = afterUrl.split("&"); //将参数按照&符分成数组

    for (var i = 0; i < urlParamArr.length; i++) {
      var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
      //如果键要删除的不一致，则加入到参数中

      if (paramArr[0] !== paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }

  if (arr.length > 0) {
    nextUrl = "?" + arr.join("&");
  }

  url = beforeUrl + nextUrl;
  return url;
};

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}

export { requestAnimation };
export default util;