var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GlobalFooter", {
    staticClass: "i-copyright",
    attrs: { links: _vm.links, copyright: _vm.copyright }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }