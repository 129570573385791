import request from '@/plugins/request';
import qs from 'qs';
var appMark = 'zhongou'; //分页查询角色权限列表

export function GetRoleList(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/pageQueryRole',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //添加修改角色

export function AddRole(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/addRole',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //获取所有菜单权限

export function GetMenuList(params) {
  return request({
    url: '/omnimedia-web-usercenter/permission/getAllPermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //素材分组查询

export function GetMaterialTag(params) {
  return request({
    url: '/omnimedia-web-advertisecenter/materialTag/queryMaterialTag',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //图片素材分页查询

export function GetPictureMaterial(params) {
  return request({
    url: '/omnimedia-web-advertisecenter/pictureMaterial/queryPictureMaterialByPage',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //音频素材查询

export function GetAudioMaterialByPage(params) {
  return request({
    url: '/omnimedia-web-advertisecenter/audioMaterial/queryAudioMaterialByPage',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //音频素材新增/修改

export function addMusicMaterial(data) {
  return request({
    url: '/omnimedia-web-advertisecenter/audioMaterial/addMusicMaterial',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //语言查询

export function GetLanguageDropDownList(params) {
  return request({
    url: '/omnimedia-web-applicationcenter/baseConfig/getLanguageDropDownList',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //机构配置分页查询

export function GetOrganizationPageQuery(params) {
  return request({
    url: '/omnimedia-web-organization/organization/pageQuery',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}
export function GetOrgType(params) {
  return request({
    url: '/omnimedia-web-organization/orgType/pageQuery',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //机构类型的新增修改

export function addOrganization(data) {
  return request({
    url: '/omnimedia-web-organization/orgType/add',
    method: 'post',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //机构类型树形菜单查询

export function GetOrgTreeQuery(params) {
  return request({
    url: '/omnimedia-web-organization/orgType/treeQuery',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //删除机构类型

export function DeletOrgById(data) {
  return request({
    url: '/omnimedia-web-organization/orgType/delById',
    method: 'POST',
    data: qs.stringify(data),
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 查询机构类型菜单权限数据

export function queryOrgTypePermission(params) {
  return request({
    url: '/omnimedia-web-organization/orgType/queryOrgTypePermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //授权功能菜单

export function saveOrgTypePermission(data) {
  return request({
    url: '/omnimedia-web-organization/orgType/saveOrgTypePermission',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 组织机构新增修改

export function addMechanism(data) {
  return request({
    url: '/omnimedia-web-organization/organization/add',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 根据id删除机构

export function delById(data) {
  return request({
    url: '/omnimedia-web-organization/organization/delById',
    method: 'POST',
    data: qs.stringify(data),
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //根据id查询机构

export function loadDepartment(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryTree',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 根据机构类型查询机构列表

export function queryOrganizationByOrganTypeId(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryOrganizationByOrganTypeId',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 根据id查询机构详情

export function queryById(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryById',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 根据id查询机构详情

export function queryByTypeId(params) {
  return request({
    url: '/omnimedia-web-organization/orgType/queryById',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询机构菜单权限数据

export function queryOrgPermission(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryOrgPermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //机构授权菜单

export function saveOrgPermission(data) {
  return request({
    url: '/omnimedia-web-organization/organization/saveOrgPermission',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 查询角色菜单权限数据

export function GetRolePermission(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryRolePermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} // 授权角色功能菜单

export function saveRolePermission(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveRolePermission',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //获取用户列表

export function GetUserList(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/pageQueryUser',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //新增用户

export function AddUser(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/addUser',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //删除用户

export function DeleteUserById(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/deleteUserById',
    method: 'POST',
    data: qs.stringify(data),
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询用户菜单权限数据

export function GetUserListPermission(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryUserPermission',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //授权用户功能菜单

export function saveUserPermission(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveUserPermission',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //根据id查询子机构的机构类型

export function queryChildOrgTypeById(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryChildOrgTypeById',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据规则至用户关联表

export function saveUserDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveUserDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}
export function queryUserDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryUserDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询角色数据规则权限

export function queryRoleDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryRoleDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据规则至角色关联表

export function saveRoleDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveRoleDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询机构数据规则权限

export function queryOrgDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryOrgDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据规则至机构关联表

export function saveOrgDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/organization/saveOrgDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询机构数据权限

export function queryOrgSpecialDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/organization/queryOrgSpecialDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据权限至机构关联表

export function saveOrgSpecialDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/organization/saveOrgSpecialDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询角色数据权限

export function queryRoleSpecialDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryRoleSpecialDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据权限至机构关联表

export function saveRoleSpecialDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveRoleSpecialDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询用户数据权限

export function queryUserSpecialDataRules(params) {
  return request({
    url: '/omnimedia-web-organization/userRole/queryUserSpecialDataRules',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //保存数据权限至机构关联表

export function saveUserSpecialDatarule(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/saveUserSpecialDatarule',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //获取语言下拉菜单（新）

export function getLangforAdd(params) {
  return request({
    url: '/omnimedia-web-substancecenter/articleRelease/addarticledropDownList/language',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //获取语言下拉菜单（新）

export function getLangforAdd_org(params) {
  return request({
    url: '/omnimedia-web-substancecenter/organArticle/addarticledropDownList/language',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //成员重置密码

export function resetPasswords(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/resetPasswords',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //修改密码

export function changePasswords(data) {
  return request({
    url: '/omnimedia-web-organization/userRole/changePasswords',
    method: 'POST',
    data: data,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询所属机构树形下拉菜单(机构文章)

export function belongOrganTree(params) {
  return request({
    url: '/omnimedia-web-substancecenter/organArticle/dropDownList/belongOrganTree',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询发布机构树形下拉菜单(机构文章)

export function publishOrganTree(params) {
  return request({
    url: '/omnimedia-web-substancecenter/organArticle/dropDownList/publishOrganTree',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询地域限制下拉菜单

export function existRegion(params) {
  return request({
    url: '/omnimedia-web-substancecenter/organArticle/dropDownList/existRegion',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询所属机构树形下拉菜单(普通文章)

export function belongOrganTree_ordinary(params) {
  return request({
    url: '/omnimedia-web-substancecenter/articleRelease/dropDownList/belongOrganTree',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
} //查询发布机构树形下拉菜单(普通文章)

export function publishOrganTree_ordinary(params) {
  return request({
    url: '/omnimedia-web-substancecenter/articleRelease/dropDownList/publishOrganTree',
    method: 'get',
    params: params,
    headers: {
      'appMark': appMark + '.web'
    }
  });
}